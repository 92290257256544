import { API_BASE_URL, GRAPHQL_FILE_URL } from "../env";
import { httpClient } from "./http-client";
import { Thunder } from "./zeus";
import { Auth0Token } from "./Auth0Token";

export const queryFetcher = async function (
  query: string,
  variables?: Record<string, any>,
  signal?: AbortSignal
) {
  const auth0TokenInstance = Auth0Token.getInstance();
  let token;
  try {
    token = await auth0TokenInstance.getAccessToken();
  } catch (e) {
    // do nothing
  }

  const result = await httpClient(
    API_BASE_URL,
    { query, variables, token, signal },
    GRAPHQL_FILE_URL
  );

  return result.data;
};

export const api = Thunder(queryFetcher, queryFetcher);

export * from "./zeus";
