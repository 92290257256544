import { GetTokenSilentlyOptions, LogoutOptions } from "@auth0/auth0-react";

export class Auth0Token {
  private static instance: Auth0Token;
  private tokenGenerator?: (
    options?: GetTokenSilentlyOptions
  ) => Promise<string>;
  private logoutFunction?: (options?: LogoutOptions) => void;

  private constructor() {}
  public static getInstance(): Auth0Token {
    if (!Auth0Token.instance) {
      Auth0Token.instance = new Auth0Token();
    }
    return Auth0Token.instance;
  }

  setTokenGenerator(tokenGenerator: {
    (options?: GetTokenSilentlyOptions): Promise<string>;
  }) {
    this.tokenGenerator = tokenGenerator;
  }

  setLogoutFunction(logoutFunction: (options?: LogoutOptions) => void): void {
    this.logoutFunction = logoutFunction;
  }

  async getAccessToken() {
    let token;
    if (this.tokenGenerator) {
      token = await this.tokenGenerator();
    }
    return token;
  }

  logout() {
    if (this.logoutFunction) {
      this.logoutFunction();
    }
  }
}
