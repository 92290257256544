import { ApiError } from "../api-client/http-client";

export const findNested = (
  obj: Record<string, unknown>,
  key: string,
  memo: ApiError[] = []
): ApiError[] => {
  let i;
  const proto = Object.prototype;
  const ts = proto.toString;
  const hasOwn = proto.hasOwnProperty.bind(obj);

  if ("[object Array]" !== ts.call(memo)) memo = [];

  for (i in obj) {
    if (hasOwn(i)) {
      if (i === key) {
        memo.push(obj[i] as ApiError);
      } else if (
        "[object Array]" === ts.call(obj[i]) ||
        "[object Object]" === ts.call(obj[i])
      ) {
        findNested(obj[i] as Record<string, unknown>, key, memo);
      }
    }
  }

  return memo;
};
