const {
  REACT_APP_API_BASE_URL,
  REACT_APP_API_FILE_URL,
  REACT_APP_CUSTOMER_IO_SITE_ID,
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_CLIENT_ID,
} = process.env;

export const API_BASE_URL = REACT_APP_API_BASE_URL || "";
export const GRAPHQL_FILE_URL = `${REACT_APP_API_FILE_URL || ""}/graphql`;

export const CUSTOMER_IO_SITE_ID = REACT_APP_CUSTOMER_IO_SITE_ID;

export const AUTH0_DOMAIN = REACT_APP_AUTH0_DOMAIN || "";
export const AUTH0_CLIENT_ID = REACT_APP_AUTH0_CLIENT_ID || "";
