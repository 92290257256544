import "react-chat-widget/lib/styles.css";
import "./chatbox.styles.scss";

import { GraphQLTypes } from "api-client";
import { isWeekend, isWithinInterval, set } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { featureFlags } from "featureFlags";
import Cookies from "js-cookie";
import { useSlackLiveChatPost } from "mutations/slackLiveChatPost";
import {
  getChatSession,
  useSlackLiveChatMessages,
} from "queries/slackLiveChatPoll";
import { FC, useCallback, useEffect, useState } from "react";
import {
  addResponseMessage,
  addUserMessage,
  markAllAsRead,
  renderCustomComponent,
  Widget,
} from "react-chat-widget";
import { useAnalytics } from "utils/analytics";

const SYNAPTICURE_LIVE_CHAT_LOCAL_STORAGE_KEY = "synapticure_live_chat";
const MESSAGES_LOCAL_STORAGE_KEY = "messages";

const getUserCookies = () => {
  const _cioanonid = Cookies.get("_cioanonid");
  const _cioid = Cookies.get("_cioid");
  const mixpanel = Cookies.get(
    `mp_${process.env.REACT_APP_MIXPANEL_API_KEY}_mixpanel`
  );

  return {
    _cioanonid,
    _cioid,
    mixpanel,
  };
};

const getLocalMessages = () => {
  const localMessagesString = localStorage.getItem(MESSAGES_LOCAL_STORAGE_KEY);
  if (localMessagesString) {
    try {
      return JSON.parse(localMessagesString);
    } catch (e) {
      return [];
    }
  }
  return [];
};

function isInBusinessHours() {
  const now = new Date();
  const cstNow = utcToZonedTime(now, "America/Chicago");
  const businessHoursStart = set(cstNow, { hours: 9, minutes: 0, seconds: 0 });
  const businessHoursEnd = set(cstNow, { hours: 17, minutes: 0, seconds: 0 });

  const withinBusinessHours = isWithinInterval(cstNow, {
    start: businessHoursStart,
    end: businessHoursEnd,
  });

  return withinBusinessHours && !isWeekend(cstNow);
}

const ChatBoxApp: FC = (): JSX.Element => {
  const [showBadge, setShowBadge] = useState<boolean>(false);
  const [showWidget, setShowWidget] = useState<boolean>(false);

  const { sendSlackLiveChatMessage } = useSlackLiveChatPost();
  const { messages } = useSlackLiveChatMessages(showWidget);
  const { trackEvent } = useAnalytics();

  useEffect(() => {
    if (featureFlags["enable-onboarding-live-chat"] && isInBusinessHours()) {
      setShowWidget(true);
    }
  }, []);

  useEffect(() => {
    if (showWidget) {
      const sessionMessages = getLocalMessages();
      sessionMessages.forEach(
        (
          message: GraphQLTypes["SlackLiveChatMessage"] & { isUser: boolean }
        ) => {
          if (message.isUser) {
            addUserMessage(message.text);
          } else {
            addResponseMessage(message.text);
          }
        }
      );
      markAllAsRead();
      setShowBadge(true);
    }
  }, [showWidget]);

  useEffect(() => {
    if (!messages || !messages.length) {
      return;
    }
    messages.forEach((message) => addResponseMessage(message.text));
    const mostRecentMessage = messages?.[messages.length - 1];
    const chatSession = getChatSession();
    if (mostRecentMessage) {
      localStorage.setItem(
        SYNAPTICURE_LIVE_CHAT_LOCAL_STORAGE_KEY,
        JSON.stringify({
          ts: chatSession.ts,
          postUUID: chatSession.postUUID,
          latest_ts: mostRecentMessage.ts,
        })
      );

      const sessionMessages = getLocalMessages();
      localStorage.setItem(
        MESSAGES_LOCAL_STORAGE_KEY,
        JSON.stringify([...sessionMessages, ...messages])
      );
    }
  }, [messages]);

  const handleNewUserMessage = useCallback(
    async (newMessage: string) => {
      const userCookies = getUserCookies();
      const { ts, postUUID, latest_ts } = getChatSession();
      let result;
      try {
        result = await sendSlackLiveChatMessage({
          currentURL: window.location.href,
          message: newMessage,
          ...userCookies,
          ts,
          postUUID,
        });
      } catch (e) {
        renderCustomComponent(
          () => (
            <div className="rcw-error">Not delivered! Please try again.</div>
          ),
          {}
        );
      }

      if (!result) {
        return;
      }

      localStorage.setItem(
        SYNAPTICURE_LIVE_CHAT_LOCAL_STORAGE_KEY,
        JSON.stringify({
          ts: result.ts,
          postUUID: result.postUUID,
          latest_ts: ts === result.ts && latest_ts ? latest_ts : result.ts,
        })
      );

      const sessionMessages = getLocalMessages();
      localStorage.setItem(
        MESSAGES_LOCAL_STORAGE_KEY,
        JSON.stringify([
          ...sessionMessages,
          { text: newMessage, ts: Date.now(), isUser: true },
        ])
      );
    },
    [sendSlackLiveChatMessage]
  );

  return (
    <>
      {showWidget && (
        <Widget
          title={"Welcome!"}
          subtitle={"We're here to help."}
          emojis={false}
          handleNewUserMessage={handleNewUserMessage}
          handleToggle={(status: string) => {
            trackEvent("CLICKED__LIVE_CHAT_BUTTON", { open: status });
          }}
          showBadge={showBadge}
          showTimeStamp={false}
        />
      )}
    </>
  );
};

export default ChatBoxApp;
