import { useQuery } from "react-query";

import { $, api, GraphQLTypes } from "../api-client";
import { SLACK_LIVE_CHAT_POLL } from "./CACHE_KEYS";

export const getChatSession = () => {
  const synapticure_live_chat = localStorage.getItem("synapticure_live_chat");
  let chatSession;
  if (synapticure_live_chat) {
    try {
      chatSession = JSON.parse(synapticure_live_chat);
    } catch (e) {}
  }
  return {
    ts: chatSession?.ts as string,
    postUUID: chatSession?.postUUID as string,
    latest_ts: chatSession?.latest_ts as string,
  };
};

export const querySlackLiveChatPoll = async (
  args: GraphQLTypes["SlackLiveChatPollInput"] | null
) => {
  if (args?.ts && args?.postUUID) {
    const data = await api.query(
      {
        slackLiveChatPoll: [
          { args: $`args` },
          { messages: { text: true, username: true, ts: true } },
        ],
      },
      {
        variables: {
          args,
        },
      }
    );
    return data;
  }
};

export const useSlackLiveChatMessages = (enabled: boolean) => {
  const { data, isLoading, error } = useQuery(
    [SLACK_LIVE_CHAT_POLL, getChatSession()],
    () => querySlackLiveChatPoll(getChatSession()),
    { refetchInterval: 5000, enabled }
  );

  return {
    messages: data?.slackLiveChatPoll.messages,
    error: error as Error,
    isLoading,
  };
};
