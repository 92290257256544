const { REACT_APP_SYNAPTICURE_ENV } = process.env;

type Flags = {
  readonly "enable-react-query-dev-tools": boolean;
  readonly "enable-sentry": boolean;
  readonly "enable-log-track-event": boolean;
  readonly "enable-customerio": boolean;
  readonly "enable-registration-request-a-call": boolean;
  readonly "enable-onboarding-live-chat": boolean;
  readonly "enable-onboarding-huntingtons-option": boolean;
  readonly "enable-onboarding-lbd-option": boolean;
  readonly "enable-onboarding-alzheimers-option": boolean;
};

type Config = {
  [key in keyof Flags]: {
    default: Flags[key];
    development?: Flags[key];
    devRelease?: Flags[key];
    staging?: Flags[key];
    production?: Flags[key];
  };
};

const config: Config = {
  "enable-react-query-dev-tools": {
    default: false,
    development: true,
  },
  "enable-sentry": {
    default: true,
    development: false,
  },
  "enable-log-track-event": {
    default: false,
    development: true,
  },
  "enable-customerio": {
    default: true,
    development: false,
  },
  "enable-registration-request-a-call": {
    default: true,
  },
  "enable-onboarding-live-chat": {
    default: false,
  },
  "enable-onboarding-huntingtons-option": {
    default: true,
  },
  "enable-onboarding-lbd-option": {
    default: true,
  },
  "enable-onboarding-alzheimers-option": {
    default: true,
  },
};

export const featureFlags = Object.entries(config).reduce<Flags>(
  (featureFlags, [key, value]) => {
    const envValue =
      value[REACT_APP_SYNAPTICURE_ENV] !== undefined
        ? value[REACT_APP_SYNAPTICURE_ENV]
        : value.default;
    (featureFlags[key as keyof Flags] as Flags[keyof Flags]) =
      envValue as Flags[keyof Flags];
    return featureFlags;
  },
  {} as Flags
);
