import "./i18n/config";

import mixpanel from "mixpanel-browser";
import React from "react";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";

import ChatBoxApp from "./ChatBoxApp";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

mixpanel.init(process.env.REACT_APP_MIXPANEL_API_KEY ?? "", {
  api_host: process.env.REACT_APP_MIXPANEL_PROXY_DOMAIN,
  ignore_dnt: true,
});

const rootElement = document.getElementById("chat-box-root") as HTMLElement;

const render = (ChatBoxApp: React.FC): void => {
  ReactDOM.render(
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <ChatBoxApp />
      </QueryClientProvider>
    </React.StrictMode>,
    rootElement
  );
};

if (rootElement?.hasChildNodes()) {
  ReactDOM.hydrate(<ChatBoxApp />, rootElement);
} else if (rootElement) {
  render(ChatBoxApp);
}

if (module.hot) {
  module.hot.accept("./ChatBoxApp", () => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const NextApp = require("./ChatBoxApp").default;
    render(NextApp);
  });
}
