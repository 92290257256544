/*
  Notes
  - Very unclear that many (most?) of these are actually being used.
  - Leaving them in for the moment to reduce possibility of breakage.
*/

// Misc. clicks
export const CLICKED__CAROUSEL = "clicked:Carousel";
export const CLICKED__LOGIN_FORM__EMAIL = "clicked:LoginPageEmailField";
export const CLICKED__LOGIN_FORM__BUTTON = "clicked:LoginPageButton";
export const CLICKED__HOME_NOTIFICATION_MENU = "clicked:HomeNotificationMenu";
export const CLICKED__HOME_NOTIFICATION_MENU__JOIN = "clicked:HomeNotificationMenuJoin";
export const CLICKED__HOME_NOTIFICATION_MENU__CANCEL = "clicked:HomeNotificationMenuCancel";
export const CLICKED__HAS_ALS_BUTTON = "clicked:HasALSButton";
export const CLICKED__PATIENT_FIRST_NAME_FIELD = "clicked:PatientFirstNameField";
export const CLICKED__PATIENT_LAST_NAME_FIELD = "clicked:PatientLastNameField";
export const CLICKED__PATIENT_NEXT_BUTTON = "clicked:PatientNextButton";
export const CLICKED__CAREGIVER_BUTTON = "clicked:AmCaregiverButton";
export const CLICKED__FIRST_NAME_FIELD = "clicked:FirstNameField";
export const CLICKED__LAST_NAME_FIELD = "clicked:LastNameField";
export const CLICKED__EMAIL_FIELD = "clicked:EmailField";
export const CLICKED__CAREGIVER_NEXT_BUTTON = "clicked:CaregiverNextButton";
export const CLICKED__PATIENTS_EMAIL_FIELD = "clicked:PatientsEmailField";
export const CLICKED__RELATIONSHIP_FIELD = "clicked:RelationshipField";
export const CLICKED__ZIP_CODE_FIELD = "clicked:ZipCodeField";
export const CLICKED__DATE_OF_DIAGNOSIS_FIELD = "clicked:DateOfDiagnosisField";
export const CLICKED__SIGNUP_DIAGNOSIS_FIELD = "clicked:SignupDiagnosisField";
export const CLICKED__SIGNUP_USER_TYPE_FIELD = "click:SignupUserTypeField";
export const CLICKED__SIGNUP_UNCONFIRMED_DIAGNOSIS = "clicked:UnconfirmedDiagnosis";
export const CLICKED__SIGNUP_STATE_FIELD = "clicked:SignupStateField";
export const CLICKED__SIGNUP_PREVIOUS_BUTTON = "clicked:SignupPreviousButton";
export const CLICKED__PRIMARY_ALS_CLINICIAN_FIELD = "clicked:PrimaryALSClinicianField";
export const CLICKED__SIGNUP_BUTTON = "clicked:SignUpButton";
export const CLICKED__HAS_ACCOUNT_BUTTON = "clicked:HasAccountButton";
export const CLICKED__LET_ME_IN_BUTTON = "clicked:LetMeInButton";
export const CLICKED__CALL_WITH_WHO_DROPDOWN = "clicked:CallWithWhoDropdown";
export const CLICKED__TIME_OF_CALL = "clicked:TimeOfCall";
export const CLICKED__CLEAR_TIME_OF_CALL = "clicked:ClearTimeOfCall";
export const CLICKED__MORE_TIMES_RIGHT = "clicked:MoreTimesRight";
export const CLICKED__MORE_TIMES_DOWN = "clicked:MoreTimesDown";
export const CLICKED__MORE_TIMES_LEFT = "clicked:MoreTimesLeft";
export const CLICKED__PHONE_NUMBER_FIELD = "clicked:PhoneNumberField";
export const CLICKED__TIME_ZONE_FIELD = "clicked:TimeZoneField";
export const CLICKED__TELEHEALTH_CONSENT_FIELD = "clicked:TelehealthConsentField";
export const CLICKED__SCHEDULE_BY_EMAIL_CHECKBOX = "clicked:schduleByEmailCheckbox";
export const CLICKED__TOS_LINK = "clicked:ToSLink";
export const CLICKED__PRIVACY_POLICY_LINK = "clicked:PrivacyPolicyLink";
export const CLICKED__TELEHEALTH_CONSENT_LINK = "clicked:TelehealthConsentLink";
export const CLICKED__NOTICE_OF_PRIVACY_PRACTICES_LINK = "clicked:NoticeOfPrivacyPracticesLink";
export const CLICKED__SIGNUP_REQUEST_A_CALL_LINK = "clicked:SignupRequestACallLink";
export const CLICKED__SIGNUP_REQUEST_A_CALL_SUBMIT = "clicked:SignupRequestACallSubmit";
export const CLICKED__CALL_TYPE_FIELD = "clicked:CallTypeField";
export const CLICKED__BIRTH_SEX_FIELD = "clicked:BirthSexField";
export const CLICKED__BIRTH_DATE_FIELD = "clicked:BirthDateField";
export const CLICKED__SCHEDULE_BUTTON__SCHEDULING_PAGE = "clicked:ScheduleButton:SchedulingPage";
export const CLICKED__PREVIOUS_BUTTON__SCHEDULING_PAGE = "clicked:PreviousButton:SchedulingPage";
export const CLICKED__PREFERRED_NAME_FIELD = "clicked:PreferredNameField";
export const CLICKED__ETHNICITY_FIELD = "clicked:EthnicityField";
export const CLICKED__RACE_FIELD = "clicked:RaceField";
export const CLICKED__GENDER_YOU_IDENTIFY_WITH_FIELD = "clicked:GenderYouIdentifyWithField";
export const CLICKED__PREFERRED_PRONOUNS_FIELD = "clicked:PreferredPronounsField";
export const CLICKED__VETERAN_STATUS_FIELD = "clicked:VeteranStatusField";
export const CLICKED__EDUCATION_FIELD = "clicked:EducationField";
export const CLICKED_SUBMIT_BUTTON__MORE_INFO_PAGE = "clickedSubmitButton:MoreInfoPage";
export const CLICKED__BE_A_PART_OF_SYNAPTICURE_BUTTON = "clicked:BeAPartOfSynapticureButton";
export const CLICKED__SCHEDULE_A_CALL = "clicked:ScheduleACall";
export const CLICKED__SCHEDULE_CALL_MODAL__CLOSED = "clicked:CloseModal";
export const CLICKED__SCHEDULE_CALL_MODAL__MORE_INFO = "clicked:MoreInfo";
export const CLICKED__SCHEDULE_CALL_MODAL__WHAT_IS_CALL_ABOUT = "clicked:WhatIsThisCallAbout";
export const CLICKED__SCHEDULE_CALL_MODAL__PREVIOUS_DATE = "clicked:PreviousDate";
export const CLICKED__SCHEDULE_CALL_MODAL__NEXT_DATE = "clicked:NextDate";
export const CLICKED__SCHEDULE_CALL_MODAL__CALENDAR_TIME = "clicked:CalendarTime";
export const CLICKED__SCHEDULE_CALL_MODAL__SCHEDULE_NEW_APPOINTMENT = "clicked:ScheduleNewAppointment";
export const CLICKED__SIDEBAR__SCHEDULE_CALL = "clicked:ScheduleACall";
export const CLICKED__SIDEBAR__FOR_ME = "clicked:ForMe";
export const CLICKED__SIDEBAR__FOR_ME_HOME = "clicked:ForMeHome";
export const CLICKED__SIDEBAR__FOR_ME_PROFILE = "clicked:ForMeProfile";
export const CLICKED__SIDEBAR__FOR_ME_MEDICAL_RECORDS = "clicked:ForMeMedicalRecords";
export const CLICKED__SIDEBAR__GENETICS = "clicked:Genetics";
export const CLICKED__SIDEBAR__GENETICS_ABOUT = "clicked:GeneticsAbout";
export const CLICKED__SIDEBAR__BIOMARKERS = "clicked:Biomarkers";
export const CLICKED__SIDEBAR__BIOMARKERS_ABOUT = "clicked:BiomarkersAbout";
export const CLICKED__SIDEBAR__THERAPIES_AND_TRIALS = "clicked:TherapiesAndTrials";
export const CLICKED__SIDEBAR__THERAPIES_AND_TRIALS_ABOUT = "clicked:TherapiesAndTrialsAbout";
export const CLICKED__SIDEBAR__LOGOUT = "clicked:Logout";
export const CLICKED__SIDEBAR__CONTACT_US = "clicked:ContactUs";
export const CLICKED__SIDEBAR__SYNAPTICURE_WEB = "clicked:SynapticureWeb";
export const CLICKED__LIVE_CHAT_BUTTON = "clicked:OpenLiveChat";

// Page loads
export const PAGE_LOADED__ADD_MORE_INFO = "pageLoaded:AddMoreInfo";
export const PAGE_LOADED__ALS_CLINICS = "pageLoaded:ALSClinics";
export const PAGE_LOADED__BIOMARKERS = "pageLoaded:Biomarkers";
export const PAGE_LOADED__CLINICAL_TRIALS = "pageLoaded:ClinicalTrials";
export const PAGE_LOADED__EXTRA_INFO_ADDED = "pageLoaded:ExtraInfoAdded";
export const PAGE_LOADED__GENETICS = "pageLoaded:Genetics";
export const PAGE_LOADED__INSURANCE_NAVIGATION = "pageLoaded:InsuranceNavigation";
export const PAGE_LOADED__LOGIN = "pageLoaded:Login";
export const PAGE_LOADED__HOME_PAGE = "pageLoaded:HomePage";
export const PAGE_LOADED__MEDICAL_RECORDS = "pageLoaded:MedicalRecords";
export const PAGE_LOADED__MESSAGING = "pageLoaded:Messaging";
export const PAGE_LOADED__NUTRACEUTICAL = "pageLoaded:Nutraceutical";
export const PAGE_LOADED__PROFILE = "pageLoaded:Profile";
export const PAGE_LOADED__WORLD_CLASS_MOVEMENT_DISORDERS_CLINICS = "pageLoaded:WorldClassMovementDisordersClinics";
export const PAGE_LOADED__GENETICS_PD = "pageLoaded:GeneticsForPD";
export const PAGE_LOADED__CLINICAL_TRIALS_PD = "pageLoaded:ClinicalTrialsForPD";

// This one is weird. A page load event for opening a modal?
export const PAGE_LOADED__SIGNUP_DIAGNOSIS = "pageLoaded:SignupDiagnosis";
export const PAGE_LOADED__SCHEDULE_CALL_MODAL = "pageLoaded:ScheduleCallModal";
export const PAGE_LOADED__SCHEDULE_CALL_PAGE = "pageLoaded:ScheduleCallPage";
export const PAGE_LOADED__TELENEUROLOGY = "pageLoaded:Teleneurology";
export const PAGE_LOADED__THERAPIES_AND_TRIALS = "pageLoaded:TherapiesAndTrials";
export const PAGE_LOADED__REGISTRATION_PAGE = "pageLoaded:RegistrationPage";
export const PAGE_LOADED__THANK_YOU_PAGE = "pageLoaded:ThankYouPage";
export const PAGE_LOADED__NOT_ELIGIBLE_PAGE = "pageLoaded:NotEligiblePage";
export const PAGE_LOADED__TELEHEALTH_CONSENT = "pageLoaded:TelehealthConsent";
export const PAGE_LOADED__NOTICE_OF_PRIVACY_PRACTICES = "pageLoaded:NoticeOfPrivacyPractices";
export const PAGE_LOADED__PRIVACY_POLICY = "pageLoaded:PrivacyPolicy";
export const PAGE_LOADED__TERMS_OF_SERVICE = "pageLoaded:TermsOfService";

// Mutations
export const MUTATION__APPOINTMENT_CREATED = "mutation:AppointmentCreated";
export const MUTATION__APPOINTMENT_CANCELED = "mutation:AppointmentCanceled";
export const MUTATION__APPOINTMENT_RESCHEDULED = "mutation:AppointmentRescheduled";
export const MUTATION__MESSAGE_SENT = "mutation:MessageSent";
export const MUTATION__USER_REGISTERED = "mutation:UserRegistered";
export const MUTATION_SLACK_LIVE_CHAT_POST = "mutation:SlackLiveChatPost";

// Others
export const OPENED_EMAIL__CALL_CONFIRMATION_EMAIL = "openedEmail:CallConfirmationEmail";
export const OPENED_EMAIL__INVITE_EMAIL = "openedEmail:InviteEmail";
export const SUBMITTED_INTAKE_FORM = "submitted:IntakeForm";
