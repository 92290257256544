import { useMutation } from "react-query";

import { $, api, GraphQLTypes } from "../api-client";
import { useAnalytics } from "../utils/analytics";

export const mutationSlackLiveChatPost = async (
  slackLiveChatPostInput: GraphQLTypes["SlackLiveChatPostInput"]
) => {
  const { slackLiveChatPost } = await api.mutation(
    {
      slackLiveChatPost: [
        {
          args: $`slackLiveChatPostInput`,
        },
        {
          ts: true,
          postUUID: true,
        },
      ],
    },
    {
      variables: {
        slackLiveChatPostInput,
      },
      operationName: "SlackLiveChatMessagePost",
    }
  );

  return slackLiveChatPost;
};

export const useSlackLiveChatPost = () => {
  const { trackEvent } = useAnalytics();
  const { mutateAsync, isLoading, data, error } = useMutation(
    mutationSlackLiveChatPost,
    {
      onSuccess() {
        trackEvent("MUTATION_SLACK_LIVE_CHAT_POST");
      },
    }
  );

  return { isLoading, sendSlackLiveChatMessage: mutateAsync, data, error };
};
