import { featureFlags } from "featureFlags";
import mixpanel from "mixpanel-browser";
import { createContext, useContext } from "react";

import * as events from "./analytics-events";

export type EventName = keyof typeof events;

type Options = string | Record<string, unknown>;
type Trackers = Record<EventName, (options?: Options) => void>;
type Properties = Record<string, unknown>;

export { events };

// Deprecated. Use `trackEvent` instead.
// @ts-ignore
export const trackers: Trackers = Object.keys(events).reduce(
  (result: Record<EventName, (options?: Options) => void>, key: string) => {
    return {
      ...result,
      [key as EventName]: (options?: Options) =>
        trackEvent(key as EventName, options),
    };
  },
  {}
);

export const identify = (external_id: string): void => {
  mixpanel.identify(external_id);
};

export const trackEvent = (name: EventName, params?: Options): void => {
  // Backward compatibility.
  const parsedParams =
    typeof params === "string" ? { details: params } : params;

  if (featureFlags["enable-log-track-event"]) {
    console.log("Analytics: ", name, params);
  }

  // Mixpanel
  mixpanel.track(events[name], parsedParams);
};

export const registerOnce = (properties: Properties): void => {
  mixpanel.register_once(properties);
};

// Could DI this via context provider if we wanted to customize it.
export const AnalyticsContext = createContext({
  identify,
  trackEvent,
  registerOnce,
});

export const useAnalytics = () => {
  return useContext(AnalyticsContext);
};
