import { extractFiles } from "extract-files";
import * as Sentry from "@sentry/react";

import { findNested } from "../utils/find-nested";

export const oopsMessage =
  "Oops, something went wrong. Please contact us - info@synapticure.com";

export type httpClient = {
  query: string;
  variables?: Record<string, any>;
  token?: string;
  signal?: AbortSignal;
};

export type ApiError = {
  message: string;
  status: string;
};

export async function httpClient<T = any>(
  baseUrl: string,
  { query, variables, token, signal }: httpClient,
  fileUrl?: string
): Promise<{ data: T }> {
  const extracted = extractFiles({
    query,
    variables,
  });

  let url = baseUrl;

  const hasFiles = extracted.files.size > 0;
  const authHeader = token ? { Authorization: `Bearer ${token}` } : null;
  const headers = {
    ...(!hasFiles ? { "Content-Type": "application/json" } : {}),
    // to use access-token probably needed axios request interceptor
    // 'x-hasura-admin-secret': 'adminsecret',
    ...(authHeader || {}),
    "x-client-timezone": Intl.DateTimeFormat().resolvedOptions().timeZone,
  };

  let body: string | FormData = JSON.stringify({
    query,
    variables,
  });

  if (hasFiles) {
    const form = new FormData();
    form.append("operations", JSON.stringify(extracted.clone));

    const map: Record<number, string[]> = {};
    let i = 0;
    extracted.files.forEach((paths) => {
      map[++i] = paths;
    });

    form.append("map", JSON.stringify(map));
    i = 0;
    extracted.files.forEach((_paths, file) => {
      form.append(++i + "", file as File);
    });

    body = form;
    url = fileUrl || url;
  }

  const response = await fetch(url || "", {
    method: "POST",
    headers,
    body,
    mode: "cors",
    signal,
  }).catch(function (err) {
    Sentry.withScope(function (scope) {
      // group errors together based on their request and response
      scope.setFingerprint([query, String(err.statusCode)]);
      Sentry.captureException(err);
    });

    return err;
  });

  const json = await response.json();

  if (json?.errors?.[0]?.extensions?.code === "invalid-jwt") {
    return json;
  }

  if (json?.errors?.length) {
    Sentry.captureException(json);
    if (json?.errors?.[0]?.message) {
      const msg = json?.errors?.[0]?.message;

      if (/Unexpected error value/.test(msg)) {
        throw Error(msg.match(/message: "(.*?)"/)?.[1] || msg);
      }
      throw Error(json?.errors?.[0]?.message);
    }

    if (json?.errors?.[0].extensions?.internal) {
      throw new Error(
        json?.errors?.[0].extensions?.internal?.response?.body || oopsMessage
      );
    }

    if (json?.errors?.message) {
      throw new Error(json?.errors?.message);
    }

    throw new Error(oopsMessage);
  }

  const errors = findNested(json, "error");

  errors.forEach((error: ApiError) => {
    if (error?.status === "error") {
      throw Error(error?.message || oopsMessage);
    }
  });

  return json;
}
